import React, {useEffect} from 'react'
import topImage from '../img/privacy-policy.jpg'
import {Helmet} from "react-helmet";



export default function PrivacyPolicy() {

    



  return (
    <>
    <Helmet>
        <title>Privacy Policy | SBA Tax Consultants and Accountant</title>
        <meta name="description" content="SBA Tax Consultants respects online privacy, protecting information to ensure the safety of our members and visitors who log on to our website." />
        <meta name="keywords" content="Filing Tax Returns, Providing Tax Advice, Preparing Tax Documents, Keeping Up With Tax Laws" />
    </Helmet>
        <section>
            <img  data-aos="fade-up" data-aos-delay="500"  data-aos-offset="50" src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
            <div className="container">            
                <div className='bannerText'>
                    <h1 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Privacy Policy </h1>
                    <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">At SBA Tax Consultants, we take the privacy of our users very seriously.</p>
                    {/* <p className=''>for SBA Tax Consultants </p> */}
                </div>            
            </div>
            
        </section>
        <section className=''>
        <div className="container">
            <div className="row align-items-center justify-content-center py-5" >
                <div className="col-md-10 py-5">
                 <h3 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold'>Privacy Policy for SBA Tax Consultants</h3> 
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">At SBA Tax Consultants, we take the privacy of our users very seriously. This policy outlines how we collect, use, and protect your personal information when you visit our website.</p>
                <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">What information do we collect?</h4>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We may collect personal information from you when you register on our site, subscribe to our newsletter, fill out a form, or place an order. This information may include your name, email address, mailing address, phone number, credit card details, and other relevant information.</p>
                <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">How do we use your information?</h4>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We may use the information collected from you in the following ways:</p>
                
                <ul>
                  <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">
                  To personalize your experience: Your information helps us to better respond to your individual needs and tailor our services to meet your requirements.</li>  
                  <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">To improve our website: We continually strive to improve our website based on the information and feedback we receive from you.</li>  
                  <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">To process transactions: Your information, whether public or private, will not be sold, exchanged, transferred or given to any other company for any reason without your consent, other than for the express purpose of delivering the purchased product or service requested.</li>  
                  <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"> To send periodic emails: The email address you provide may be used to send you information and updates pertaining to your order, in addition to receiving occasional company news, updates, related product or service information, etc. Note: If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.</li>  
                  
                </ul>
                
                
                
               
                <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">How do we protect your information?</h4>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We implement a variety of security measures to maintain the safety of your personal information when you place an order or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.</p>
                <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Do we disclose any information to outside parties?</h4>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We do not sell, trade or otherwise transfer your personally identifiable information to outside parties. This does not include trusted third parties who assist us in operating our website, conducting our business or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property or safety.</p>
                <h5 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Online Privacy Policy Only</h5>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">This online privacy policy applies only to information collected through our website and not to information collected offline.</p>
                <h5 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Changes to our Privacy Policy</h5>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We may update this privacy policy from time to time. Any changes will be posted on this page.</p>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Your Consent:</p>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">By using our site, you consent to our websites privacy policy.</p>
                <h5 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Contacting Us</h5>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">If there are any questions regarding this privacy policy you may contact us using the information below.</p>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Email: info@sbataxconsultants.com</p>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Address: 123 Main Street, Anytown, USA</p>
                <h5 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Conclusion</h5>
                <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">SBA Tax Consultants is committed to protecting your privacy and providing a secure online experience. We adhere to all relevant US regulations with IRS for handling of personal information. By using our website, you agree to the terms outlined in this privacy policy. We reserve the right to make changes to this policy at any time, so please check back periodically for updates. If you have any questions or concerns, please do not hesitate to contact us. Thank you for choosing SBA Tax Consultants.  So whether you are an individual looking for tax consultation or a business aiming for financial success, trust that your personal information is safe with us. We value your privacy and will continue to ensure the security of your data as we strive to provide you with excellent services.  Remember, at SBA Tax Consultants, your success is our priority!  Thank you for choosing us.</p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
