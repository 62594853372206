import React from 'react'
import consumer from '../img/sector/consumer.svg';
import energy from '../img/sector/energy.svg';
import financial from '../img/sector/financial.svg';
import media from '../img/sector/media.svg';
import tech from '../img/sector/tech.svg';
import telecom from '../img/sector/telecom.svg';

export default function Industry() {
  return (
    <>
        <section className='py-5'>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 pb-5">
                        <p className='text-center text-danger h5' data-aos="fade-up" data-aos-offset="350">We Serve</p>
                        <h2 className='text-center fw-bold' data-aos="fade-up" data-aos-offset="350">Industries / Sector</h2>
                        
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="100">
                        <div className="indus-box">
                            <div className='img'><img src={consumer} alt="industry we served" className='img-fluid' /></div>
                            
                            <h4>Consumer Markets</h4>
                            <p>"Tax consultants optimize financial strategies for Consumer Markets, fueling growth through expert guidance and maximizing opportunities for success."</p>
                        </div>
                    </div>
                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="100">
                        <div className="indus-box">
                            <div className='img'><img src={energy} alt="industry we served" className='img-fluid' /></div>
                            
                            <h4>Energy</h4>
                            <p>"Energy sector thrives with tax consultants, navigating regulations, optimizing incentives, and enhancing financial resilience for sustainable growth and success."</p>
                        </div>
                    </div>

                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="100">
                        <div className="indus-box">
                            <div className='img'><img src={financial} alt="industry we served" className='img-fluid' /></div>
                            
                            <h4>Financial Service</h4>
                            <p>"Tax consultants empower the financial sector, strategizing efficient tax planning, compliance, and incentives to accelerate growth and ensure fiscal success."</p>
                        </div>
                    </div>

                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="100">
                        <div className="indus-box">
                            <div className='img'><img src={tech} alt="industry we served" className='img-fluid' /></div>
                            
                            <h4>Technology</h4>
                            <p>"Tech sector excels with tax consultants, streamlining deductions, navigating credits, and optimizing financial strategies for sustained growth and innovation."</p>
                        </div>
                    </div>

                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="100">
                        <div className="indus-box">
                            <div className='img'><img src={media} alt="industry we served" className='img-fluid' /></div>
                            
                            <h4>Media</h4>
                            <p>"Media thrives with tax consultants, leveraging incentives, optimizing deductions, and navigating regulations to fuel growth and financial prosperity seamlessly."</p>
                        </div>
                    </div>

                    <div className="col-md-4" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="100">
                        <div className="indus-box">
                            <div className='img'><img src={telecom} alt="industry we served" className='img-fluid' /></div>
                            
                            <h4>Telecom</h4>
                            <p>"Telecom excels with tax consultants, navigating regulations, optimizing incentives, and strategizing financial plans to drive growth and sustained success seamlessly."</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
