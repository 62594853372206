import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/food-permit-license-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const FPLU = () => { 
    
  return (
    <>
    <Helmet>
        <title>Get your USA Food Permit License with SBA Tax Consultants</title>
        <meta name="description" content="Need a food permit license in the USA? SBA Tax Consultants can help with applications for food business and vendor permits. Contact us now!" />
        <meta name="keywords" content="Food permit license USA, Food business permit USA, USA food license application, Food vendor permit USA, USA food handler permit" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Get your USA Food Permit License with SBA Tax Consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get your USA Food Permit License with SBA Tax Consultants" />
        <meta name="twitter:image" content="https://tinyurl.com/bdc9bupb"  />
        <meta name="https://sbataxconsultants.com/food-permit-license-usa" content="Food permit license USA, Food business permit USA" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Get your USA Food Permit License with SBA Tax Consultants"type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/food-permit-license-usa" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get your USA Food Permit License with SBA Tax Consultants" />
        <meta property="og:description" content="Need a food permit license in the USA? SBA Tax Consultants can help with applications for food business and vendor permits. Contact us now!" />
        <meta property="og:url" content="https://sbataxconsultants.com/food-permit-license-usa" />
        <meta property="og:site_name" content="Get your USA Food Permit License with SBA Tax Consultants"/>
        <meta property="og:url" content="https://sbataxconsultants.com/lottery-business-license-usa" />
        <meta property="og:image" content="https://tinyurl.com/bdc9bupb" />
        <meta property="og:image:secure_url " content="https://tinyurl.com/bdc9bupb" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@graph": [
                  {
                      "@type": "BreadcrumbList",
                      "@id":"https://sbataxconsultants.com/food-permit-license-usa/#breadcrumblist",
                      "itemListElement": [
                          {
                              "@type": "ListItem",
                              "@id": "https://sbataxconsultants.com/#listItem",
                              "position": 1,
                              "item": {
                                  "@type": "WebPage",
                                  "@id": "https://sbataxconsultants.com/",
                                  "name": "Home",
                                  "description": "Get expert tax consulting services in the USA with trusted US tax experts.Optimize your tax filing, planning, and compliance with professional guidance today!",
                                  "url": "https://sbataxconsultants.com/"
                              },
                              "nextItem": "https://sbataxconsultants.com/food-permit-license-usa/#listItem"
                          },
                          {
                              "@type": "ListItem",
                              "@id": "https://sbataxconsultants.com/food-permit-license-usa/#listItem",
                              "position": 2,
                              "item": {
                                  "@type": "WebPage",
                                  "@id": "https://sbataxconsultants.com/food-permit-license-usa/",
                                  "name": "Get your USA Food Permit License with SBA Tax Consultants",
                                  "description":"Need a food permit license in the USA? SBA Tax Consultants can help with applications for food business and vendor permits. Contact us now!",
                                  "url": "https://sbataxconsultants.com/food-permit-license-usa/"
                              },
                              "previousItem": "https://sbataxconsultants.com/#listItem"
                          }
                      ]
                  },
                  {
                      "@type": "Organization",
                      "@id": "https://sbataxconsultants.com/#organization",
                      "name": "Get your USA Food Permit License with SBA Tax Consultants",
                      "url": "https://sbataxconsultants.com/",
                      "logo": {
                          "@type": "ImageObject",
                          "url": "https://sbataxconsultants.com/static/media/logo.aa6783c29d4854ba8429.webp",
                          "@id": "https://sbataxconsultants.com/#organizationLogo",
                          "width": 310,
                          "height": 217,
                          "caption": "SBA Tax Consultants"
                      },
                      "image": { "@id": "https://sbataxconsultants.com/#organizationLogo" },
                      "sameAs": [
                          "https://www.facebook.com/sbataxconsultants/",
                          "https://x.com/sbataxconsult/",
                          "https://www.instagram.com/sba_taxconsultants/",
                          "https://www.linkedin.com/company/99196153/admin/dashboard/"
                      ],
                      "contactPoint": { "@type": "ContactPoint", "telephone": "+1 469 722 5480", "contactType": "Customer Support" }
                  },
                  {
                        "@type": "WebPage",
                      "@id": "https://sbataxconsultants.com/food-permit-license-usa/#webpage",
                      "url": "https://sbataxconsultants.com/food-permit-license-usa/",
                      "name": "Get your USA Food Permit License with SBA Tax Consultants",
                      "description":"Need a food permit license in the USA? SBA Tax Consultants can help with applications for food business and vendor permits. Contact us now!",
                      "inLanguage": "en-US",
                      "isPartOf": { "@id": "https://sbataxconsultants.com/#website" },
                      "breadcrumb": { "@id":"https://sbataxconsultants.com/food-permit-license-usa/#breadcrumblist" },
                      "datePublished": "2023-09-25T07:26:03+00:00",
                      "dateModified": "2024-09-24T13:38:17+00:00"

                  },
                  {
                      "@type": "WebSite",
                      "@id": "https://sbataxconsultants.com/#website",
                      "url": "https://sbataxconsultants.com/",
                      "name": "Get your USA Food Permit License with SBA Tax Consultants",
                      "alternateName":"Get your USA Food Permit License with SBA Tax Consultants",
                      "inLanguage": "en-US",
                      "publisher": { "@id": "https://sbataxconsultants.com/#organization" }
                  }
              ]
          }

          `}
        </script>
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Food Permit License USA</strong></h1>
                <p className='text-white py-3 ppp'>Get your USA food permit license hassle-free with expert guidance from SBA Tax Consultants!</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Get Your Food Permit License USA with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Secure your food permit license in the USA effortlessly with SBA Tax Consultants. Our expert team specializes in handling USA food license applications, vendor permits, and USA food handler permits. We streamline your licensing process to ensure a hassle-free experience. Trust us to manage all aspects of your food permit needs efficiently and professionally.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default FPLU;