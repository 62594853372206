import React from 'react'

import icon1 from '../img/icons/office-building.png'
import icon2 from '../img/icons/expertise.png'
import icon3 from '../img/icons/rate.png'
import { useEffect } from 'react'

export default function BelowSliderSec() {

  


  return (
    <section className='py-5 xx abouttt'>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h3 className='fw-bold'><strong><img src={icon1} alt="" className="img-fluid iconn" /> Head Office USA</strong></h3>
            <p>Our head office works 24/7 for all your tax service needs.</p>
          </div>
          <div className="col-md-4">
            <h3 className='fw-bold'><strong><img src={icon2} alt="" className="img-fluid iconn" />10+ Years Experience</strong></h3>
            <p>Over 10 years of expertise in tax solutions, We offer expert guidance for all your tax needs.</p>
          </div>
          <div className="col-md-4">
            <h3 className='fw-bold'><strong><img src={icon3} alt="" className="img-fluid iconn" />Client Satisfaction</strong></h3>
            <p>We deliver personalized and precise tax solutions, guaranteeing top-notch client satisfaction.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
