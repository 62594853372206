import axios from 'axios';

import topImage from '../img/contact-us.jpg'
import {Helmet} from "react-helmet";

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import Promise from 'bluebird';
export default function Contact() {


    const sheetKey = '1E5-4JIiCMCFQkE9_JGdIT7H6FqR_Yvkjo-DuknInIOk';
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
    const [currentPage,setCurrentPage] = useState('')
  
  const form = useRef();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    Mobile: '',
    email: '',
    subject: '',
    message: '',
    recaptchaValue: null, // Initialize the reCAPTCHA value
  });
  
  const credentials = require('../client_secret.json');

  // Google Sheets spreadsheet key
  const doc = new GoogleSpreadsheet(sheetKey);

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {

    setCurrentPage(window.location.pathname.split('/').pop())


    // Load the reCAPTCHA script when the component mounts

    if (showRecaptcha) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      document.head.appendChild(script);
    }
  }, [showRecaptcha]);



  const [error, setError] = useState(null);


  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!formData.recaptchaValue) {
  //     setError('Please complete the reCAPTCHA verification.');
  //     return;
  //   }

  //   // Reset the error state
  //   setError(null);

  //   // Save data to Google Sheets
  //   try {
  //     const doc = new GoogleSpreadsheet(sheetKey);
  //     await doc.useServiceAccountAuth(credentials);
  //     await doc.loadInfo();
  //     const sheet = doc.sheetsByIndex[0];
  //     const promisifiedAddRow = Promise.promisify(sheet.addRow, { context: sheet });
  //     await promisifiedAddRow({
  //       Timestamp: new Date(),
  //       // ... add more fields as needed
  //       ...formData,
  //     });
  //     console.log('Data saved to Google Sheets');
  //   }  catch (error) {
  //     console.error('Error saving data to Google Sheets', error);
  //   }

  //   // Send email using EmailJS
  //   // try {
  //   //   const emailResult = await emailjs.sendForm(
  //   //     'service_8p2ngld',
  //   //     'template_zw4a1dn',
  //   //     form.current,
  //   //     '3SvwfiC7gDlSrHSvx'
  //   //   );
  //   //   navigate('/thank-you');
  //   //   //alert('Email sent successfully', emailResult);
  //   // } catch (error) {
  //   //   console.error('Error sending email', error);
  //   // }

    


  //   setFormData({
  //     first_name: '',
  //     last_name: '',
  //     Mobile: '',
  //     email: '',
  //     subject: '',
  //     message: '',
  //   });
  // };





  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    // Validate reCAPTCHA
    // suru kaarna hai
    // if (!formData.recaptchaValue) {
    //   setError('Please complete the reCAPTCHA verification.');
    //   return;
    // }
    
    // Reset any previous error
    setError(null);
    

    // Prepare the data to be sent to the API
    const payload = {
      firstName: formData.first_name, // Match backend's `firstName`
      lastName: formData.last_name,   // Match backend's `lastName`
      mobile: formData.Mobile,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
      pageUrl: 'contact-us', // Optional: Change this if required by your API
    };
    
    console.log("in the handel submit",payload)
    console.log("in the formData---->",formData)
  
    try {
      // Send data to your API endpoint using Axios
      const response = await axios.post('https://sbk-backend.vercel.app/submit-form', payload);
  
      if (response.status === 200) {
        // Success: reset the form
        alert('Form submitted successfully!');
        setFormData({
          first_name: '',
          last_name: '',
          Mobile: '',
          email: '',
          subject: '',
          message: '',
          recaptchaValue: null,
        });
      } else {
        // If the server responds with a status other than 200
        setError(`Error: ${response.status} - ${response.data.message || 'Submission failed'}`);
      }
    } catch (error) {
      // Handle errors during the request
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again later.');
    }
  };
  


  

  const handleRecaptchaChange = (value) => {
    // Update the form state with the reCAPTCHA value
    setFormData({
      ...formData,
      recaptchaValue: value,
    });

    // Clear the error when the user completes the reCAPTCHA
    setError(null);
  };

  const handleInputChange = (e) => {
    // Update the form data as the user types
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (!showRecaptcha) {
      setShowRecaptcha(true);
    }

  };

  
  return (
    
    <>
    <Helmet>
        <title>Contact Us for Tax Filing in USA</title>
        <meta name="description" content="Contact SBA Tax Consultants for expert tax filing in the USA. Get professional help to ensure accurate and timely tax returns. Reach out today" />
        <meta name="keywords" content="Tax Filing in USA, Tax consultant in usa, industrial tax consultant in usa, Tax consultants in usa" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Contact Us for Tax Filing in USA" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us for Tax Filing in USA" />
        <meta name="twitter:image" content="https://sbataxconsultants.com/static/media/contact-us.73f9dd19f6823e065164.jpg"  />
        <meta name="https://sbataxconsultants.com/contact-us" content="industrial tax consultant in usa" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Contact Us for Tax Filing in USA" type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/contact-us" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us for Tax Filing in USA" />
        <meta property="og:description" content="Contact SBA Tax Consultants for expert tax filing in the USA. Get professional help to ensure accurate and timely tax returns. Reach out today" />
        <meta property="og:url" content="https://sbataxconsultants.com/contact-us" />
        <meta property="og:site_name" content="Contact Us for Tax Filing in USA"/>
        <meta property="og:image" content="https://sbataxconsultants.com/static/media/contact-us.73f9dd19f6823e065164.jpg"  />
        <meta property="og:image:secure_url " content="https://sbataxconsultants.com/static/media/contact-us.73f9dd19f6823e065164.jpg" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:type" content="image/jpg" />
        <script type="application/ld+json">
          {`
             {
              "@context": "https://schema.org",
              "@graph": [
                  {
                      "@type": "BreadcrumbList",
                      "@id": "https://sbataxconsultants.com/contact-us/#breadcrumblist",
                      "itemListElement": [
                          {
                              "@type": "ListItem",
                              "@id": "https://sbataxconsultants.com/contact-us/#listItem",
                              "position": 1,
                              "item": {
                                  "@type": "WebPage",
                                  "@id": "https://sbataxconsultants.com/",
                                  "name": "Home",
                                  "description": "Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!",
                                  "url": "https://sbataxconsultants.com/"
                              },
                              "nextItem": "https://sbataxconsultants.com/contact-us/#listItem"
                          },
                          {
                              "@type": "ListItem",
                              "@id": "https://sbataxconsultants.com/contact-us/#listItem",
                              "position": 2,
                              "item": {
                                  "@type": "WebPage",
                                  "@id": "https://sbataxconsultants.com/contact-us/",
                                  "name": "Contact Us for Tax Filing in USA",
                                  "description": "Contact SBA Tax Consultants for expert tax filing in the USA. Get professional help to ensure accurate and timely tax returns. Reach out today.",
                                  "url": "https://sbataxconsultants.com/contact-us/"
                              },
                              "previousItem": "https://sbataxconsultants.com/contact-us/#listItem"
                          }
                      ]
                  },
                  {
                      "@type": "Organization",
                      "@id": "https://sbataxconsultants.com/contact-us/#organization",
                      "name": " Contact Us for Tax Filing in USA",
                      "url": "https://sbataxconsultants.com/contact-us/",
                      "logo": {
                          "@type": "ImageObject",
                          "url": "https://sbataxconsultants.com/static/media/logo.aa6783c29d4854ba8429.webp",
                          "@id": "https://sbataxconsultants.com/contact-us/#organizationLogo",
                          "width": 310,
                          "height": 217,
                          "caption": "SBA Tax Consultants"
                      },
                      "image": { "@id": "https://sbataxconsultants.com/contact-us/#organizationLogo" },
                      "sameAs": [
                          "https://www.facebook.com/sbataxconsultants/",
                          "https://x.com/sbataxconsult/",
                          "https://www.instagram.com/sba_taxconsultants/",
                          "https://www.linkedin.com/company/99196153/admin/dashboard/"
                      ],
                      "contactPoint": { "@type": "ContactPoint", "telephone": "+1 469 722 5480", "contactType": "Customer Support" }
                  },
                  {
                      "@type": "WebPage",
                      "@id": "https://sbataxconsultants.com/contact-us/#webpage",
                      "url": "https://sbataxconsultants.com/contact-us/",
                      "name": " Contact Us for Tax Filing in USA",
                      "description": "Contact SBA Tax Consultants for expert tax filing in the USA. Get professional help to ensure accurate and timely tax returns. Reach out today.",
                      "inLanguage": "en-US",
                      "isPartOf": { "@id": "https://sbataxconsultants.com/contact-us/#website" },
                      "breadcrumb": { "@id": "https://sbataxconsultants.com/contact-us/#breadcrumblist" },
                      "datePublished": "2023-09-25T07:26:03+00:00",
                      "dateModified": "2024-09-24T13:38:17+00:00"
                  },
                  {
                      "@type": "WebSite",
                      "@id": "https://sbataxconsultants.com/contact-us/#website",
                      "url": "https://sbataxconsultants.com/contact-us/",
                      "name": " SBA Tax Consultants",
                      "alternateName": "Contact Us for Tax Filing in USA",
                      "inLanguage": "en-US",
                      "publisher": { "@id": "https://sbataxconsultants.com/contact-us/#organization" }
                  }
              ]
          }

          `}
        </script>
    </Helmet>
    <section>
        <img data-aos="fade-up" data-aos-delay="500"  data-aos-offset="50" src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
        <div className="container">            
            {/* <div className='bannerText'>
                <h1 className=''>Contact Us</h1>                
                <p>"Contact us to unlock growth! Tax consultants enhance financial strategies, maximize deductions, and navigate regulations for sustainable business expansion."</p>
                
            </div>             */}
        </div>
        
    </section>
    

      <section className='bg-white py-5'>
          <div className="container">
              <div className="row align-items-center justify-content-center">
              <div className="col-md-4">
                            <h1 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='h3 mb-5 secondary-text  fw-bold'>Contact Us for Tax Filing</h1>
                        </div>
                  <div className="col-md-12 bg-light py-3 px-5">
                    <div className='row align-items-center justify-content-center'>
                        
                        <div className="col-md-5">
                            <h2 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">USA Office</h2>                
                            <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"><i className="bi bi-geo-alt"></i> 8500 N Stemmons Fwy, Dallas, Texas 75247</p>
                            <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"><a href="tel: +1 469 722 5480"><i className="bi bi-telephone-forward"></i> +1 469 722 5480</a></p>
                            <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"><a href='mailto: contact@sbataxconsultants.com'><i className="bi bi-envelope-open"></i> contact@sbataxconsultants.com</a></p>
                        </div>

                        <div className="col-md-5">
                            <h2 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">India Office</h2>                
                            <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"><i className="bi bi-geo-alt"></i> H-No:2-1-8/4/1, NR.Suite 2A, Saraswathi Colony, Uppal, Hyderabad, Telangana 500039</p>
                            <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"><a href="tel:+91 99676 23999"><i className="bi bi-telephone-forward"></i> +91 99676 23999</a></p>
                            <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150"><a href='mailto: contact@sbataxconsultants.com'><i className="bi bi-envelope-open"></i> contact@sbataxconsultants.com</a></p>
                        </div>
                        
                        
                    </div>
                      
                  </div>
                  <div className="col-md-6 bg-light py-3 px-5 mt-4">
                    <div className='row align-items-center justify-content-center'>
                        
                        <div className="col-md-12" data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3352.6495279317337!2d-96.87448612482676!3d32.8280499813771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9c5c0e8f2bfb%3A0xe17fec961c506a92!2s8500%20N%20Stemmons%20Fwy%2C%20Dallas%2C%20TX%2075247%2C%20USA!5e0!3m2!1sen!2sin!4v1704030797638!5m2!1sen!2sin" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        
                    </div>
                      
                  </div>

                  <div className="col-md-6 pt-4" id='Cont_form'>
                    <small data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Have Some Questions?</small>
                    <h2 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold primary-text'>Let's Chat</h2>

                    <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Feel free to get in touch with us.</p>
                    <form data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='row' autoComplete='off' ref={form} onSubmit={handleFormSubmit}>
                        {/* Your other form fields */}

                        <div className="col-md-6 form-group mb-4">                                    
                            <input value={formData.first_name} onChange={handleInputChange} type="text" name='first_name' placeholder='First Name *' onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control" required />
                        </div>

                        <div className="col-md-6 form-group mb-4">                                    
                            <input value={formData.last_name} onChange={handleInputChange} type="text" name='last_name' placeholder='Last Name *' onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control" required />
                        </div>

                        <div className="col-md-6 form-group mb-4">                                    
                            <input value={formData.Mobile} onChange={handleInputChange} type="text" maxLength={13} minLength={10} name='Mobile' placeholder='Mobile / Phone No *' className="form-control" onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '');}} required />
                        </div>

                        <div className="col-md-6 form-group mb-4">                                    
                            <input value={formData.email} onChange={handleInputChange} type="email" name='email' placeholder='Email Adress*' className="form-control" required />
                        </div>

                        <div className="col-md-12 form-group mb-4">                                    
                            <input value={formData.subject} onChange={handleInputChange} type="text" name='subject' placeholder='Subject*' className="form-control" required />
                        </div>

                        <div className="col-md-12 form-group mb-4">                                    
                            <textarea value={formData.message} onChange={handleInputChange} className='form-control' rows={5} name='message' placeholder='Message'></textarea>
                        </div>
                        
                        {/* Main--  6LeeczkpAAAAAHhwk2zz9moWNOLDU_Ji61Lh1sd4   localhost--  6Lcl0ygoAAAAAHKrsouKfVZMhLnkt6qZe2ecLy9x */}



                        {/* // suru kaarna hai */}
                        {showRecaptcha && (
                        <>
                            <ReCAPTCHA
                            sitekey="6LeeczkpAAAAAHhwk2zz9moWNOLDU_Ji61Lh1sd4"
                            onChange={handleRecaptchaChange}
                            theme="light"
                            />
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </>
                        )}
                        
                        <div className="col-md-12 form-group mt-3">
                            <button type='submit' value="Send" className="btn btn-success px-5 mx-auto text-center">Submit</button>
                        </div>            
                    </form>
                    </div>

                  

              </div>
          </div>
      </section>
    </>
    
  )
}
