import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

import Img from '../img/home-page-banner-1.webp'

export default function SliderSec() {

    useEffect(() => {
        const moveFactor = 20; // Adjust movement sensitivity
        
        const handleMouseMove = (event) => {
            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;

            const moveX = ((event.clientX - centerX) / centerX) * moveFactor;
            const moveY = ((event.clientY - centerY) / centerY) * moveFactor;

            const sliderText = document.querySelector('.SliderText');
            if (sliderText) {
                sliderText.style.transform = `translate(${moveX}px, ${moveY}px)`;
            }
        };

        const handleMouseLeave = () => {
            const sliderText = document.querySelector('.SliderText');
            if (sliderText) {
                sliderText.style.transform = "translate(0, 0)";
            }
        };

        const bgVideo = document.querySelector('.bg-video');
        if (bgVideo) {
            bgVideo.addEventListener('mousemove', handleMouseMove);
            bgVideo.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (bgVideo) {
                bgVideo.removeEventListener('mousemove', handleMouseMove);
                bgVideo.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

  return (
    <>
    <Helmet>
        <title>Tax Consultant in USA | US Tax Expert</title>
        <meta name="description" content="Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!" />
        <meta name="keywords" content="Tax Consultant in USA, US Tax Expert,tax consultant business in usa,Tax Filing in usa" />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Tax Consultant in USA | US Tax Expert" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />
        <meta name="zipcode" content="75247" /> 
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"  />
        <meta name="twitter:site" content="@sbataxconsult" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Tax Consultant in USA | US Tax Expert" />
        <meta name="twitter:image" content="https://shorturl.at/3vrRs" />
        <meta name="https://sbataxconsultants.com/" content="industrial tax consultant in usa" />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Tax Consultant in USA | US Tax Expert" />
        <link rel="canonical" href="https://sbataxconsultants.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Tax Consultant in USA | US Tax Expert" />
        <meta property="og:description" content="Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!" />
        <meta property="og:url" content="https://sbataxconsultants.com/" />
        <meta property="og:site_name" content="Tax Consultant in USA | US Tax Expert" />
        <meta property="og:image" content="https://shorturl.at/3vrRs" />
        <meta property="og:image:secure_url " content="https://shorturl.at/3vrRs" />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:image:type" content="image/webp" />


        <script type="application/ld+json">
          {`
           {
                "@context": "https://schema.org",
                "@graph": [
                    {
                        "@type": "BreadcrumbList",
                        "@id": "https://sbataxconsultants.com/#breadcrumblist",
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "@id": "https://sbataxconsultants.com/#listItem",
                                "position": 1,
                                "item": {
                                    "@type": "WebPage",
                                    "@id": "https://sbataxconsultants.com/",
                                    "name": "Home",
                                    "description": "Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!",
                                    "url": "https://sbataxconsultants.com/"
                                },
                                "nextItem": "https://sbataxconsultants.com/#listItem"
                            },
                            {
                                "@type": "ListItem",
                                "@id": "https://sbataxconsultants.com/#listItem",
                                "position": 2,
                                "item": {
                                    "@type": "WebPage",
                                    "@id": "https://sbataxconsultants.com/",
                                    "name": "Tax Consultant in USA | US Tax Expert",
                                    "description": "Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!",
                                    "url": "https://sbataxconsultants.com/"
                                },
                                "previousItem": "https://sbataxconsultants.com/#listItem"
                            }
                        ]
                    },
                    {
                        "@type": "Organization",
                        "@id": "https://sbataxconsultants.com/#organization",
                        "name": "Tax Consultant in USA | US Tax Expert",
                        "url": "https://sbataxconsultants.com/",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://sbataxconsultants.com/static/media/logo.aa6783c29d4854ba8429.webp",
                            "@id": "https://sbataxconsultants.com/#organizationLogo",
                            "width": 310,
                            "height": 217,
                            "caption": "SBA Tax Consultants"
                        },
                        "image": { "@id": "https://sbataxconsultants.com/#organizationLogo" },
                        "sameAs": [
                            "https://www.facebook.com/sbataxconsultants/",
                            "https://x.com/sbataxconsult/",
                            "https://www.instagram.com/sba_taxconsultants/",
                            "https://www.linkedin.com/company/99196153/admin/dashboard/"
                        ],
                        "contactPoint": { "@type": "ContactPoint", "telephone": "+1 469 722 5480", "contactType": "Customer Support" }
                    },
                    {
                        "@type": "WebPage",
                        "@id": "https://sbataxconsultants.com/#webpage",
                        "url": "https://sbataxconsultants.com/",
                        "name": "SBA Tax Consultants",
                        "description": "Get expert tax consulting services in the USA with trusted US tax experts. Optimize your tax filing, planning, and compliance with professional guidance today!",
                        "inLanguage": "en-US",
                        "isPartOf": { "@id": "https://sbataxconsultants.com/#website" },
                        "breadcrumb": { "@id": "https://sbataxconsultants.com/#breadcrumblist" },
                        "datePublished": "2023-09-25T07:26:03+00:00",
                        "dateModified": "2024-09-24T13:38:17+00:00"
                    },
                    {
                        "@type": "WebSite",
                        "@id": "https://sbataxconsultants.com/#website",
                        "url": "https://sbataxconsultants.com/",
                        "name": "SBA Tax Consultants",
                        "alternateName": "Tax Consultant in USA | US Tax Expert",
                        "inLanguage": "en-US",
                        "publisher": { "@id": "https://sbataxconsultants.com/#organization" }
                    }
                ]
            }

         `}
      </script>

       
      </Helmet>

      <section className='bg-video'>
        
        {/* <video autoPlay muted="muted" loop poster="" className="video">
            <source src={video} type="video/mp4" className='video' />
        </video> */}
        <img src={Img} className='img-fluid' alt='Banner Img' />
        <div className='SliderText'>
            <h1 data-aos="fade-up" className='border-bottom border-2 mb-3 pb-3'>Welcome to <br></br><span className='fw-bold primary-text'>SBA Tax Consultants</span></h1>
            <h4 data-aos="fade-up" className='fw-bold mb-5'>With our knowledgeable staff and commitment to excellence, we strive to provide top-notch service to individuals and businesses alike.</h4>
            <a data-aos="fade-up" data-aos-delay="200" data-aos-offset="50" className='btn btn-danger' href="tel:+1 469 722 5480"><span className='h4 primary-text px-4'><i className="bi bi-telephone-forward"></i> Call Us </span></a>&nbsp;&nbsp;&nbsp;<a data-aos="fade-up" data-aos-delay="200" className='btn btn-danger' href="./contact-us"><span className='h4 px-2 primary-text'>Get in Touch</span></a>
        </div>
        
        {/* <div id="demo" className='container d-none'>
            <div className="col-md-12 position-relative">                
                <div className='SliderText'>
                    
                    <p className=''>With our knowledgeable staff and commitment to excellence, we strive to provide top-notch service to individuals and businesses alike.</p>
                    <a href="#contactUs"><button className='btn btn-success px-5 py-3'>Get In Touch ! <i className="bi bi-envelope"></i></button></a>
                </div>
            </div>
        </div>  */}
      
    </section>
    </>
    
  )
}
