import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from '../../../Spinner.gif';

import { Toast, ToastContainer } from 'react-bootstrap';

function HomeAdmin() {

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");



    const tableStyle = { border: '1px solid #dee2e6' };

    const [apiResponse, setApiResponse] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [delLoading, setdelLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filterRange, setFilterRange] = useState('all');
    const [modalData, setModalData] = useState({ message: '', name: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const adminRole = localStorage.getItem('role');


    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch('https://sbk-backend.vercel.app/get-all-data');
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const result = await response.json();

                const serviceData = result.filter(item => item.pageUrl === 'home');
                setApiResponse(result);
                setFilteredData(serviceData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Handle checkbox selection for rows
    const handleRowSelection = (id) => {
        const updatedSelection = selectedRows.includes(id)
            ? selectedRows.filter((rowId) => rowId !== id)
            : [...selectedRows, id];
        setSelectedRows(updatedSelection);
    };

    // Handle "Select All" checkbox
    const handleSelectAll = () => {
        if (isAllSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(filteredData.map((item) => item._id));
        }
        setIsAllSelected(!isAllSelected);
    };

    // Handle delete for a single row
    // const handleDeleteRow = async (id) => {
    //     try {
    //         const response = await fetch('https://sbk-backend.vercel.app/delete-data', {
    //             method: 'DELETE',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ ids: [id] }), // Send an array of IDs
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             setFilteredData((prevData) => prevData.filter((item) => item._id !== id));
    //             setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
    //             console.log(`Item with ID ${id} deleted successfully: ${data.message}`);
    //             alert(data.message);
    //         } else {
    //             const errorData = await response.json();
    //             console.error('Failed to delete the item:', errorData || 'Unknown error');
    //             alert('Failed to delete the item. Please try again.');
    //         }
    //     } catch (error) {
    //         console.error('Error deleting the item:', error);
    //         alert('An error occurred while deleting the item. Please try again later.');
    //     }
    // };




    const handleDeleteRow = async (id) => {
        try {
            setdelLoading(true)
            const response = await fetch('https://sbk-backend.vercel.app/delete-data', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ids: [id] }), // Send an array of IDs
            });


            if (response.ok) {
                const data = await response.json();

                // Hide spinner by setting delLoading to false
                setdelLoading(false);

                setToastMessage(data.message);
                setShowToast(true);

                // Remove the deleted item from filteredData and selectedRows
                setFilteredData((prevData) => prevData.filter((item) => item._id !== id));
                setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));

                // Log success message
                console.log(`Item with ID ${id} deleted successfully: ${data.message}`);

                // Show the alert after hiding the spinner
                // alert(data.message);
            }


            else {
                setdelLoading(false)

                setToastMessage('Failed to delete item');
                setShowToast(true);


                const errorData = await response.json();
                console.error('Failed to delete the item:', errorData || 'Unknown error');
                alert('Failed to delete the item. Please try again.');
            }
        } catch (error) {
            console.error('Error deleting the item:', error);
            alert('An error occurred while deleting the item. Please try again later.');
            setdelLoading(false)

            setToastMessage('An error occurred');
            setShowToast(true);

        }
        finally {

            setdelLoading(false)
        }


    };





    const handleFilterChange = (range) => {
        setFilterRange(range);
        const now = new Date();
        let filtered;

        // Keep the current page URL context
        const currentPageData = apiResponse.filter((item) => item.pageUrl === 'home'); // Replace 'contact' dynamically if needed

        switch (range) {
            case '7d':
                filtered = currentPageData.filter(
                    (item) => new Date(item.submittedAt) >= new Date(now - 7 * 24 * 60 * 60 * 1000)
                );
                break;
            case '31d':
                filtered = currentPageData.filter(
                    (item) => new Date(item.submittedAt) >= new Date(now - 31 * 24 * 60 * 60 * 1000)
                );
                break;
            case '90d':
                filtered = currentPageData.filter(
                    (item) => new Date(item.submittedAt) >= new Date(now - 90 * 24 * 60 * 60 * 1000)
                );
                break;
            case '1y':
                filtered = currentPageData.filter(
                    (item) => new Date(item.submittedAt) >= new Date(now - 365 * 24 * 60 * 60 * 1000)
                );
                break;
            default:
                filtered = currentPageData;
        }
        setFilteredData(filtered);
    };



    // Handle delete for all selected rows
    // const handleDeleteSelected = async () => {
    //     try {
    //         const response = await fetch('https://sbk-backend.vercel.app/delete-data', {
    //             method: 'DELETE',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ ids: selectedRows }), // Send all selected IDs
    //         });

    //         if (response.ok) {
    //             const data = await response.json();
    //             setFilteredData((prevData) => prevData.filter((item) => !selectedRows.includes(item._id)));
    //             setSelectedRows([]);
    //             setIsAllSelected(false);
    //             console.log(`Selected items deleted successfully: ${data.message}`);
    //             alert(data.message);
    //         } else {
    //             const errorData = await response.json();
    //             console.error('Failed to delete selected items:', errorData || 'Unknown error');
    //             alert('Failed to delete selected items. Please try again.');
    //         }
    //     } catch (error) {
    //         console.error('Error deleting selected items:', error);
    //         alert('An error occurred while deleting selected items. Please try again later.');
    //     }
    // };



    const handleDeleteSelected = async () => {
        try {

            setdelLoading(true)


            const response = await fetch('https://sbk-backend.vercel.app/delete-data', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ids: selectedRows }), // Send all selected IDs
            });

            if (response.ok) {



                // Hide spinner by setting delLoading to false
                setdelLoading(false);

                const data = await response.json();


                setToastMessage(data.message);
                setShowToast(true);



                setFilteredData((prevData) => prevData.filter((item) => !selectedRows.includes(item._id)));
                setSelectedRows([]);
                setIsAllSelected(false);
                console.log(`Selected items deleted successfully: ${data.message}`);
                // alert(data.message);
            } else {
                const errorData = await response.json();
                console.error('Failed to delete selected items:', errorData || 'Unknown error');
                alert('Failed to delete selected items. Please try again.');
            }
        } catch (error) {
            setdelLoading(false)

            setToastMessage('An error occurred',error);
            setShowToast(true);

            console.error('Error deleting selected items:', error);
            // alert('An error occurred while deleting selected items. Please try again later.');
        }
        finally {

            setdelLoading(false)
        }

    };






    // Handle modal
    const handleOpenModal = (name, message) => {
        setModalData({ name, message });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    if (loading) return <div className='d-flex justify-content-center align-items-center vh-100'>
        <img src={Spinner} alt="Loading spinner" width={'220px'} />
    </div>;
    if (error) return <p>Error: {error}</p>;


    if (delLoading) return (
        <div
        className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.12)', // Semi-transparent background
          zIndex: 1050, // High z-index to overlay other elements
        }}
      >
        {/* Bootstrap Spinner */}
        <div className="spinner-border" role="status" style={{ width: '220px', height: '220px' }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>

    );


    return (
        <div>
            <div className="d-flex justify-content-around">
                <h3>Admin Table - Home Page Inquiries</h3>
            </div>

            {selectedRows.length > 0 && (
                <div className="mb-3">
                    <button className="btn btn-danger" onClick={handleDeleteSelected} disabled={adminRole !== "super-admin"}
                    >
                        Delete Selected
                    </button>
                </div>
            )}

            <table className="table table-striped" style={tableStyle}>
                <thead className="thead-dark">
                    <tr>
                       {adminRole === "super-admin" && <th>
                            <input
                                type="checkbox"
                                checked={isAllSelected}
                                onChange={handleSelectAll}
                            />
                        </th>}
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Subject</th>
                        <th>Message</th>

                        <th>
                            <div className="d-flex  flex-column align-items-center">
                                <span>Date and Time</span>
                                <select
                                    className="form-select form-select-sm ms-2"
                                    value={filterRange}
                                    onChange={(e) => handleFilterChange(e.target.value)}
                                >
                                    <option value="all">All Time</option>
                                    <option value="7d">Last 7 Days</option>
                                    <option value="31d">Last 31 Days</option>
                                    <option value="90d">Last 90 Days</option>
                                    <option value="1y">Last 1 Year</option>
                                </select>

                            </div>
                        </th>
                        {/* <th>Date and Time</th> */}
                        <th>Page URL</th>
                       {adminRole === "super-admin" && <th>Actions</th>}
                    </tr>
                </thead>
                {/* <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={item._id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.includes(item._id)}
                                    onChange={() => handleRowSelection(item._id)}
                                />
                            </td>
                            <td>{index + 1}</td>
                            <td>{`${item.firstName} ${item.lastName}`}</td>
                            <td>{item.email}</td>
                            <td>
                                {item.message.length > 50 ? (
                                    <>
                                        {`${item.message.substring(0, 50)}...`}
                                        <button
                                            onClick={() =>
                                                handleOpenModal(`${item.firstName} ${item.lastName}`, item.message)
                                            }
                                            className="btn btn-link p-0"
                                        >
                                            Read More
                                        </button>
                                    </>
                                ) : (
                                    item.message
                                )}
                            </td>
                            <td>{new Date(item.submittedAt).toLocaleString()}</td>
                            <td>{item.pageUrl}</td>
                            <td>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDeleteRow(item._id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody> */}



                <tbody>
                    {filteredData
                        .sort((a, b) => new Date(b.submittedAt) - new Date(a.submittedAt)) // Sort by submittedAt in descending order
                        .map((item, index) => (
                            <tr key={item._id}>
                                {adminRole === "super-admin" &&<td>
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(item._id)}
                                        onChange={() => handleRowSelection(item._id)}
                                    />
                                </td>}
                                <td>{index + 1}</td>
                                <td>{`${item.firstName} ${item.lastName}`}</td>
                                <td>{item.email}</td>
                                <td>{item.mobile}</td>
                                <td>{item.subject}</td>
                                <td>
                                    {item.message.length > 50 ? (
                                        <>
                                            {`${item.message.substring(0, 50)}...`}
                                            <button
                                                onClick={() =>
                                                    handleOpenModal(`${item.firstName} ${item.lastName}`, item.message)
                                                }
                                                className="btn btn-link p-0"
                                            >
                                                Read More
                                            </button>
                                        </>
                                    ) : (
                                        item.message
                                    )}
                                </td>
                                <td>{new Date(item.submittedAt).toLocaleString()}</td>
                                <td>{item.pageUrl}</td>
                                {adminRole === "super-admin" &&<td>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDeleteRow(item._id)}
                                        disabled={adminRole !== "super-admin"}

                                    >
                                        Delete
                                    </button>
                                </td>}
                            </tr>
                        ))}
                </tbody>

            </table>

            {/* Modal */}
            {isModalOpen && (
                <div
                    className="modal show d-block"
                    style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Message from {modalData.name}</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCloseModal}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <p>{modalData.message}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer position="top-end" className="p-3 bg-white">
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Action Status</strong>
                        {/* <small>Just now</small> */}
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

        </div>
    );
}

export default HomeAdmin;
