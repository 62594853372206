import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import Promise from 'bluebird';
import axios from 'axios';


const ContactForm = () => {
  const sheetKey = '1E5-4JIiCMCFQkE9_JGdIT7H6FqR_Yvkjo-DuknInIOk';
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPage, setCurrentPage] = useState('')

  const form = useRef();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    Mobile: '',
    email: '',
    subject: '',
    message: '',
    recaptchaValue: null, // Initialize the reCAPTCHA value
  });

  const credentials = require('./client_secret.json');

  // Google Sheets spreadsheet key
  const doc = new GoogleSpreadsheet(sheetKey);

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {

    setCurrentPage(window.location.pathname.split('/').pop())

    // Load the reCAPTCHA script when the component mounts
    if (showRecaptcha) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      document.head.appendChild(script);
    }
  }, [showRecaptcha]);



  const [error, setError] = useState(null);


  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!formData.recaptchaValue) {
  //     setError('Please complete the reCAPTCHA verification.');
  //     return;
  //   }

  //   // Reset the error state
  //   setError(null);

  //   // Save data to Google Sheets
  //   try {
  //     const doc = new GoogleSpreadsheet(sheetKey);
  //     await doc.useServiceAccountAuth(credentials);
  //     await doc.loadInfo();
  //     const sheet = doc.sheetsByIndex[0];
  //     const promisifiedAddRow = Promise.promisify(sheet.addRow, { context: sheet });
  //     await promisifiedAddRow({
  //       Timestamp: new Date(),
  //       // ... add more fields as needed
  //       ...formData,
  //     });
  //     console.log('Data saved to Google Sheets');
  //   }  catch (error) {
  //     console.error('Error saving data to Google Sheets', error);
  //   }

  //   // Send email using EmailJS
  //   try {
  //     const emailResult = await emailjs.sendForm(
  //       'service_8p2ngld',
  //       'template_zw4a1dn',
  //       form.current,
  //       '3SvwfiC7gDlSrHSvx'
  //     );
  //     navigate('/thank-you');
  //     //alert('Email sent successfully', emailResult);
  //   } catch (error) {
  //     console.error('Error sending email', error);
  //   }

  //   setFormData({
  //     first_name: '',
  //     last_name: '',
  //     Mobile: '',
  //     email: '',
  //     subject: '',
  //     message: '',
  //   });
  // };







  // -------------------- try to copy form inc Sticky form------------------

  

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validate reCAPTCHA
    // suru kaarna hai
    // if (!formData.recaptchaValue) {
    //   setError('Please complete the reCAPTCHA verification.');
    //   return;
    // }

    // Reset any previous error
    setError(null);


    // Prepare the data to be sent to the API
    const payload = {
      firstName: formData.first_name, // Match backend's `firstName`
      lastName: formData.last_name,   // Match backend's `lastName`
      mobile: formData.Mobile,
      email: formData.email,
      subject: formData.subject,
      message: formData.message,
      pageUrl: 'home', // Optional: Change this if required by your API
    };

    console.log("in the handel submit", payload)
    console.log("in the formData---->", formData)

    try {
      // Send data to your API endpoint using Axios
      const response = await axios.post('https://sbk-backend.vercel.app/submit-form', payload);
      console.log("inside the try", payload)

      if (response.status === 200) {
        // Success: reset the form
        alert('Form submitted successfully!');
        setFormData({
          first_name: '',
          last_name: '',
          Mobile: '',
          email: '',
          subject: '',
          message: '',
          recaptchaValue: null,
        });
      } else {
        // If the server responds with a status other than 200
        setError(`Error: ${response.status} - ${response.data.message || 'Submission failed'}`);
      }
    } catch (error) {
      // Handle errors during the request
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again later.');
    }
  };










  const handleRecaptchaChange = (value) => {
    // Update the form state with the reCAPTCHA value
    setFormData({
      ...formData,
      recaptchaValue: value,
    });

    // Clear the error when the user completes the reCAPTCHA
    setError(null);
  };

  const handleInputChange = (e) => {
    // Update the form data as the user types
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (!showRecaptcha) {
      setShowRecaptcha(true);
    }

  };



  return (
    <section className='py-5' id='contactUs'>
      <div className="container">

        <div className="row justify-content-center align-items-center">
          <div className="col-md-12 text-center pb-5" data-aos="fade-up" data-aos-delay="500" data-aos-offset="150">
            <small>Have Some Questions?</small>
            <h2 className='fw-bold primary-text'>Let's Chat</h2>

            <p>Feel free to get in touch with us.</p>
          </div>
          <div data-aos="fade-up" data-aos-delay="500" data-aos-offset="150" className="col-md-6" id='Cont_form'>
            <form className='row' autoComplete='off' ref={form} onSubmit={handleFormSubmit}>
              {/* Your other form fields */}

              <div className="col-md-6 form-group mb-4">
                <input value={formData.first_name} onChange={handleInputChange} type="text" name='first_name' placeholder='First Name *' onKeyUp={(e) => { e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); }} className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">
                <input value={formData.last_name} onChange={handleInputChange} type="text" name='last_name' placeholder='Last Name *' onKeyUp={(e) => { e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, ''); }} className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">
                <input value={formData.Mobile} onChange={handleInputChange} type="text" maxLength={13} minLength={10} name='Mobile' placeholder='Mobile / Phone No *' className="form-control" onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, ''); }} required />
              </div>

              <div className="col-md-6 form-group mb-4">
                <input value={formData.email} onChange={handleInputChange} type="email" name='email' placeholder='Email Adress*' className="form-control" required />
              </div>

              <div className="col-md-12 form-group mb-4">
                <input value={formData.subject} onChange={handleInputChange} type="text" name='subject' placeholder='Subject*' className="form-control" required />
              </div>

              <div className="col-md-12 form-group mb-4">
                <textarea value={formData.message} onChange={handleInputChange} className='form-control' rows={5} name='message' placeholder='Message'></textarea>
              </div>

              {/* Main--  6LeeczkpAAAAAHhwk2zz9moWNOLDU_Ji61Lh1sd4   localhost--  6Lcl0ygoAAAAAHKrsouKfVZMhLnkt6qZe2ecLy9x */}

              {showRecaptcha && (
                <>
                {/* suru karna hai */}
                  <ReCAPTCHA
                    sitekey="6LeeczkpAAAAAHhwk2zz9moWNOLDU_Ji61Lh1sd4"
                    onChange={handleRecaptchaChange}
                    theme="light"
                  />
                  {/* Display the error message if reCAPTCHA is not completed */}
                  {/* Add your error state logic here */}
                  {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                </>
               )} 

              <div className="col-md-12 form-group mt-3">
                <button type='submit' value="Send" className="btn btn-success px-5 mx-auto text-center">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
