import React, {useEffect} from 'react'
import topImage from '../img/terms-of-service.jpg'
import {Helmet} from "react-helmet";

export default function Terms() {

    
  return (
    <>
    <Helmet>
        <title>Terms of Service Regarding Tax Advice and Business Terms</title>
        <meta name="description" content="At SBA, we are constantly striving for excellence for our clients. You agree to be covered by all of the information made." />
        <meta name="keywords" content="Tax Planning, Taxable Income, NRI Tax Services, IRS Tax Services" />
    </Helmet>
        <section>
            <img  data-aos="fade-up" data-aos-delay="500"  data-aos-offset="50" src={topImage} className='img-fluid w-100 bannerImg' alt='img' />
            <div className="container">            
                <div className='bannerText'>
                    <h1 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Terms of Service </h1>
                    <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className=''>"Explore our terms of service for comprehensive tax guidance. Transparent, compliant, and client-focused to ensure mutual success and satisfaction."</p>
                </div>            
            </div>
            
        </section>
        <section className='py-5'>
            <div className="container py-3">
                <div className="row">
                    <div className="col-md-12">
                        <h3 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold'>Terms of Use </h3>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className="">Welcome to SBA Tax Consultants! By accessing or using our website and services, you agree to comply with and be bound by the following Terms of Service. Please read these Terms carefully before using our services. If you do not agree to these Terms, you must not use our website or services.
                        </p>
                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Acceptance of Terms</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-normal mb-0'>By using our services, you affirm that you:</p>
                        <ul>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Are at least 18 years of age.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Have the legal capacity to enter into a binding agreement.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Agree to comply with these Terms.</li>
                        </ul>

                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Services Provided</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-normal mb-0'>SBA Tax Consultants offers the following services:</p>
                        <ul>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Tax preparation and filing assistance.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Tax consulting and advisory services.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Sales tax solutions for businesses.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Guidance on tax compliance and regulations.</li>
                        </ul>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Our services are designed to help individuals and businesses manage their tax responsibilities effectively. However, our services do not constitute legal or financial advice unless explicitly stated.</p>


                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Use of Website</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-normal mb-0'>You agree to use this website only for lawful purposes. You must not:</p>
                        <ul>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Attempt to gain unauthorized access to our systems.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Use our website for any fraudulent or malicious activity.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Copy, modify, or distribute content from the website without our written consent.</li>
                        </ul>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We reserve the right to monitor and terminate access to our website for any user violating these Terms.</p>


                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Client Responsibilities</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-normal mb-0'>To ensure the accuracy of our services, you are responsible for:</p>
                        <ul>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Providing accurate, complete, and timely information.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Keeping us updated on any changes to your tax-related circumstances.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Retaining all original documents, including receipts and financial records.</li>                           
                        </ul>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Failure to provide accurate information may affect the quality and outcome of our services.</p>


                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Confidentiality and Privacy</h4>                        
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We value your privacy. Any personal or financial information you provide will be handled in accordance with our Privacy Policy. We use reasonable measures to safeguard your data but cannot guarantee absolute security against unauthorized access.</p>

                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Fees and Payment</h4>                       
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Our fees for services will be agreed upon prior to engagement. Payments must be made in full as per the agreed schedule. We accept payments via [list payment methods, e.g., credit cards, bank transfers].</p>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-normal'>Non-payment may result in the suspension or termination of services.</p>

                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Limitations of Liability</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-normal mb-0'>SBA Tax Consultants is not liable for:</p>
                        <ul>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Any penalties, fines, or losses incurred due to inaccurate or incomplete information provided by you.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Delays in filing caused by factors outside our control.</li>
                            <li data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Indirect, incidental, or consequential damages resulting from the use of our services.</li>
                        </ul>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">Our total liability is limited to the amount paid for the service in question.</p>


                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Intellectual Property</h4>                        
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">All content on this website, including text, graphics, logos, and software, is the property of SBA Tax Consultants or its licensors. Unauthorized use of our intellectual property is prohibited.</p>
                        
                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Termination</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We reserve the right to terminate or suspend access to our services or website at any time, with or without notice, for violations of these Terms or for any other reason.</p>

                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Governing Law</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">These Terms are governed by the laws of the State of Texas, without regard to its conflict of laws principles. Any disputes will be resolved in the courts of Texas, USA.</p>

                        <h4 data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className='fw-bold py-3'>Changes to Terms</h4>
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150">We may update these Terms periodically. The updated version will be posted on this page with the revised date. Continued use of our services after updates indicates your acceptance of the revised Terms.</p>
                        
                        <p data-aos="fade-up" data-aos-delay="500"  data-aos-offset="150" className="">If you have questions about these Terms, please contact us.</p>
                        
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
