import React, { useState, useEffect } from 'react';

import Inc_non_sticky_form from '../Inc_non_sticky_form';
import whychoooseus from '../img/service/Tobacco-Licenses-in-USA.webp'
import {Helmet} from "react-helmet";
import About_akshay from '../About_akshay';
import ServiceOffered from './ServiceOffered';

import BelowSliderSec from './BelowSliderSec';
import WhyChoose from './WhyChoose';

const TobaccoLicensesInUSA = () => { 
  
    
  return (
    <>
    <Helmet>
        <title>Get Your Tobacco License in USA with SBA Tax Consultants</title>
        <meta name="description" content="Apply for a tobacco license in the USA with SBA Tax Consultants. We handle applications, renewals, and licensing requirements" />
        <meta name="keywords" content="Tobacco License Application USA, Tobacco Retail License USA, Buy Tobacco License USA, Tobacco Licensing Requirements USA, Tobacco License Renewal USA " />
        <meta name="robots" content="index, follow" />
        <meta name="YahooSeeker" content="Index,Follow" />
        <meta name="Googlebot" content="Index,Follow" />
        <meta name="bingbot" content="index, follow" />
        <meta name="classification" content="Get Your Tobacco License in USA with SBA Tax Consultants" />
        <meta name="allow-search" content="Yes" />
        <meta name="distribution" content="global" />
        <meta name="Content-Language" content="EN" />
        <meta name="geo.region" content="US-TX" />
        <meta name="geo.placename" content="Dallas" />

        <meta name="zipcode" content="75247" />  
        <meta name="expires" content="never" />
        <meta name="language" content="English" />
        <meta name="author" content="SBA Tax Consultants" />
        <meta name="rating" content="General" />
        <meta name="audience" content="All" />
        <meta name="publisher" content="SBA Tax Consultants" />
        <meta name="city" content="Dallas" />
        <meta name="State" content="Texas" />
        <meta name="twitter:creator" content="@sbataxconsult"/>
        <meta name="twitter:site" content="@sbataxconsult"/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Get Your Tobacco License in USA with SBA Tax Consultants" />
        <meta name="twitter:image" content="https://tinyurl.com/yu679j5j"  />
        <meta name=" https://sbataxconsultants.com/tobacco-licenses-in-usa" content="Tobacco License Application USA, Tobacco Retail License USA,  " />
        <link href="https://sbataxconsultants.com/opensearch.xml" rel="search" title="Get Your Tobacco License in the USA with SBA Tax Consultants."type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://sbataxconsultants.com/tobacco-licenses-in-usa" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get Your Tobacco License in the USA with SBA Tax Consultants." />
        <meta property="og:description" content="Apply for a tobacco license in the USA with SBA Tax Consultants. We handle applications, renewals, and licensing requirements" />
        <meta property="og:url" content=" https://sbataxconsultants.com/tobacco-licenses-in-usa" />
        <meta property="og:site_name" content="Get Your Tobacco License in the USA with SBA Tax Consultants."/>
        <meta property="og:image" content="https://tinyurl.com/yu679j5j" />
        <meta property="og:image:secure_url " content="https://tinyurl.com/yu679j5j" />
        <meta property="og:image:width" content="1080"/>
        <meta property="og:image:height" content="1080"/>
        <meta property="og:image:type" content="image/jpg" />

        <script type="application/ld+json">
          {`
            {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": "BreadcrumbList",
                    "@id": "https://sbataxconsultants.com/tobacco-licenses-in-usa/#breadcrumblist",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "@id": "https://sbataxconsultants.com/#listItem",
                            "position": 1,
                            "item": {
                                "@type": "WebPage",
                                "@id": "https://sbataxconsultants.com/",
                                "name": "Home",
                                "description": "Get expert tax consulting services in the USA with trusted US tax experts.Optimize your tax filing, planning, and compliance with professional guidance today!",
                                "url": "https://sbataxconsultants.com/"
                            },
                            "nextItem": "https://sbataxconsultants.com/tobacco-licenses-in-usa/#listItem"
                        },
                        {
                            "@type": "ListItem",
                            "@id": "https://sbataxconsultants.com/tobacco-licenses-in-usa/#listItem",
                            "position": 2,
                            "item": {
                                "@type": "WebPage",
                                "@id": "https://sbataxconsultants.com/tobacco-licenses-in-usa/",
                                "name": "Tobacco Licenses in USA",
                                "description":"Apply for a tobacco license in the USA with SBA Tax Consultants.We handle applications, renewals, and licensing requirements",
                                "url": "https://sbataxconsultants.com/tobacco-licenses-in-usa/"
                            },
                            "previousItem": "https://sbataxconsultants.com/#listItem"
                        }
                    ]
                },
                {
                    "@type": "Organization",
                    "@id": "https://sbataxconsultants.com/#organization",
                    "name": "Tobacco Licenses in USA",
                    "url": "https://sbataxconsultants.com/",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://sbataxconsultants.com/static/media/logo.aa6783c29d4854ba8429.webp",
                        "@id": "https://sbataxconsultants.com/#organizationLogo",
                        "width": 310,
                        "height": 217,
                        "caption": "SBA Tax Consultants"
                    },
                    "image": { "@id": "https://sbataxconsultants.com/#organizationLogo" },
                    "sameAs": [
                        "https://www.facebook.com/sbataxconsultants/",
                        "https://x.com/sbataxconsult/",
                        "https://www.instagram.com/sba_taxconsultants/",
                        "https://www.linkedin.com/company/99196153/admin/dashboard/"
                    ],
                    "contactPoint": { "@type": "ContactPoint", "telephone": "+1 469 722 5480", "contactType": "Customer Support" }
                },
                {
                    "@type": "WebPage",
                    "@id": "https://sbataxconsultants.com/tobacco-licenses-in-usa/#webpage",
                    "url": "https://sbataxconsultants.com/tobacco-licenses-in-usa/",
                    "name": "Tobacco Licenses in USA",
                    "description":"Apply for a tobacco license in the USA with SBA Tax Consultants.We handle applications,renewals,and licensing requirements",
                    "inLanguage": "en-US",
                    "isPartOf": { "@id": "https://sbataxconsultants.com/#website" },
                    "breadcrumb": { "@id": "https://sbataxconsultants.com/tobacco-licenses-in-usa/#breadcrumblist" },
                    "datePublished": "2023-09-25T07:26:03+00:00",
                    "dateModified": "2024-09-24T13:38:17+00:00"
                },
                {
                    "@type": "WebSite",
                    "@id": "https://sbataxconsultants.com/#website",
                    "url": "https://sbataxconsultants.com/",
                    "name": "Get Your Tobacco License in USA with SBA Tax Consultants",
                    "alternateName": "Get Your Tobacco License in USA with SBA Tax Consultants",
                    "inLanguage": "en-US",
                    "publisher": { "@id": "https://sbataxconsultants.com/#organization" }
                }
            ]
        }

          `}
        </script>
    </Helmet>

    <section className='bg-newww position-relative'>
      <div className="container py-5 dddd">
        <div className="row align-items-center">
            
            <div className="col-md-8">
                <h1 className='text-white'><strong>Tobacco Licenses in USA</strong></h1>
                <p className='text-white py-3 ppp'>Looking to obtain a tobacco license in the USA? SBA Tax Consultants offer expert guidance on tobacco licensing requirements and applications. Contact us for streamlined, professional assistance.</p>
               
                
            </div>
            <div className="col-md-4 fixedElement">
                <Inc_non_sticky_form />
            </div>
        
        </div>
        </div>
    </section>
    
    
    <BelowSliderSec></BelowSliderSec>
    <ServiceOffered></ServiceOffered>
    <WhyChoose></WhyChoose>
    
    

    <section className='whychooseuSS pt-5'>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h2 className='pb-3 h1'><b>Tobacco Licensing Services with <span className='primary-text'>SBA Tax Consultants</span></b></h2>
            <p>Navigating the Tobacco License Application USA process can be challenging, but we streamline the experience for you at SBA Tax Consultants. Whether you're seeking a Tobacco Retail License or need assistance with your Tobacco License Application USA, our expert team is here to help. We understand the intricate Tobacco Licensing Requirements USA and ensure your application Meets all necessary criteria.</p>
            <a href='#Cont_form'><button className="btn btn-alert px-5 lt-1">GET IN TOUCH WITH US!</button></a>
          </div>
          <div className="col-md-5">
            <img src={whychoooseus} alt="why choose us" className="img-fluid whyyimg" />
          </div>
        </div>
      </div>
    </section>



    <About_akshay></About_akshay>
    </>
  )
}
export default TobaccoLicensesInUSA;
