import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

function AuthAdmin({ onLoginSuccess }) {
  const [loginData, setLoginData] = useState({
    emailOrUsername: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  // const [showCPassword, setShowCPassword] = useState(false); // For toggling confirm password visibility

  // Helper function to set cookies
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  };

  // Handle Login Form Submission
  const handleLoginSubmit = async (e) => {
    e.preventDefault();


    try {
      // Adjust the base URL if your backend runs on a different port
      const response = await axios.post("https://sbk-backend.vercel.app/login", {
        email: loginData.emailOrUsername,
        password: loginData.password,
      });

      if (response.status === 200) {
        // const token = response.data.authToken;

        // Save token to localStorage and cookies
        // console.log('response---------------------->',response)
        // localStorage.setItem("role", response.data.role[0]);
        // console.log("response.data.role------------>",response.data.role)
        // console.log("response.data------------>",response.data)
        // console.log("response------------>",response)
        localStorage.setItem("role", response.data.role);
        setCookie("role", response.data.role);

        alert("Login successful!");
        onLoginSuccess();
        setLoginData({ emailOrUsername: "", password: "" });
      } else {
        alert(`Error: ${response.data}`);
      }
    } catch (err) {
      alert(`An error occurred: ${err.response?.data || err.message}`);
    }
  };

  return (
    <div
      className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative"
      style={{
        backgroundImage: `url(${require("../../../adminBG.jpg")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-4 col-lg-5">
            <div className="card">
              <div className="card-header py-4 text-center bg-light border-bottom">
                <a href="index.html">
                  <span>
                    <img
                      src="https://sbataxconsultants.com/static/media/logo.aa6783c29d4854ba8429.webp"
                      style={{ width: "120px" }}
                      className="img-fluid"
                      alt="Logo"
                    />
                  </span>
                </a>
              </div>

              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <h4 className="text-dark-50 text-center pb-0 fw-bold">Log In</h4>
                  <p className="text-muted mb-4">
                    Enter your email address, password, and role to access the admin panel.
                  </p>
                </div>

                <form onSubmit={handleLoginSubmit}>
                  <div className="mb-3">
                    <label htmlFor="login-emailOrUsername" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="login-emailOrUsername"
                      value={loginData.emailOrUsername}
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          emailOrUsername: e.target.value,
                        })
                      }
                      placeholder="Enter your email or username"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="login-password" className="form-label">
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="login-password"
                        value={loginData.password}
                        onChange={(e) =>
                          setLoginData({ ...loginData, password: e.target.value })
                        }
                        placeholder="Enter your password"
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </button>
                    </div>
                  </div>


                  <div className="mb-3 text-center">
                    <button className="btn btn-primary" type="submit">
                      Log In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthAdmin;
