import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeAdmin from './home/HomeAdmin';
import ContactAdmin from './contact/ContactAdmin';
import ServiceAdmin from './service/ServiceAdmin';
import AuthAdmin from './login/AuthAdmin';
import AllAdminMsg from './allMsg/AllMsg';
import axios from 'axios';
import FileSaver from 'file-saver'; // Install via npm: npm install file-saver


const Admin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activePage, setActivePage] = useState('all'); // State to track selected page

  // Check authentication status on component mount
  useEffect(() => {
    const authToken = localStorage.getItem('role');
    setIsLoggedIn(!!authToken);
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true); // Update state after login
  };

  const handleLogout = () => {
    localStorage.removeItem('role');
    document.cookie = 'auth=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    setIsLoggedIn(false); // Update state after login
  }

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const sidebarStyle = {
    width: isCollapsed ? '5%' : '15%',
    transition: 'width 0.3s',
    backgroundColor: '#343a40',
    color: 'white',
    height: '100vh',
    position: 'fixed',
  };

  const contentStyle = {
    marginLeft: isCollapsed ? '5%' : '15%',
    width: isCollapsed ? '95%' : '85%',
    padding: '20px',
    transition: 'margin-left 0.3s, width 0.3s',
  };

  const handleLinkClick = (page) => {
    setActivePage(page);
  };




  // const handleExportExcel = async () => {
  //   if (!selectedDateRange || !selectedSection) {
  //     alert('Please select both a date range and a section.');
  //     return;
  //   }

  //   console.log('Downloading data for:');
  //   console.log('Date Range:', selectedDateRange);
  //   console.log('Section:', selectedSection);

  //   // Add your download logic here

  //   // Close the modal after downloading
  //   setShowModal(false);



  //   try {
  //     const response = await axios.post(
  //       // 'http://localhost:5000/export-excel',
  //       'https://sbk-backend.vercel.app/export-excel',
  //       { section: selectedSection, range: selectedDateRange},
  //       { responseType: 'blob' } // Important for file downloads
  //     );

  //     // Download the file
  //     const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'messages.xlsx');
  //     document.body.appendChild(link);
  //     link.click();
  //     link.remove();
  //   } catch (error) {
  //     console.error('Error exporting Excel file:', error);
  //   }
  // };






  const handleExportExcel = async () => {
    if (!selectedDateRange || !selectedSection) {
      alert("Please select both a date range and a section.");
      return;
    }
  
    console.log("Downloading data for:");
    console.log("Date Range:", selectedDateRange);
    console.log("Section:", selectedSection);
  
    try {
      const response = await axios.post(
        "https://sbk-backend.vercel.app/export-excel", 
                // 'http://localhost:5000/export-excel',

        {
          section: selectedSection, 
          range: selectedDateRange
        },
        {
          responseType: "blob", // Ensures the response is treated as a binary file
        }
      );
  
      // Create a blob and trigger download
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "messages.xlsx"); // File name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting Excel file:", error);
  
      // Display meaningful error messages
      if (error.response && error.response.status === 500) {
        alert("An error occurred on the server while generating the Excel file.");
      } else {
        alert("An error occurred. Please check your internet connection or try again later.");
      }
    }
  };

  

  



  const [showModal, setShowModal] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [selectedSection, setSelectedSection] = useState('');






  return (
    <>
      {!isLoggedIn ? (
        <AuthAdmin onLoginSuccess={handleLoginSuccess} />
      ) : (
        <>
          {/* Sidebar */}
          <div style={sidebarStyle}>
            <div className="d-flex justify-content-between align-items-center p-2">
              <h5 className="m-0">Admin</h5>
              <button
                onClick={toggleSidebar}
                className="btn btn-sm btn-light"
                style={{ fontSize: '12px' }}
              >
                {isCollapsed ? '>' : '<'}
              </button>
            </div>
            {!isCollapsed && (
              <ul className="list-unstyled p-2">
                <li className="p-2" onClick={() => handleLinkClick('all')}>
                  <a href="#" style={{ color: 'white' }}>All Inquiries</a>
                </li>
                <li className="p-2" onClick={() => handleLinkClick('home')}>
                  <a href="#" style={{ color: 'white' }}>Home </a>
                </li>
                <li className="p-2" onClick={() => handleLinkClick('contact')}>
                  <a href="#" style={{ color: 'white' }}>Contact</a>
                </li>
                <li className="p-2" onClick={() => handleLinkClick('service')}>
                  <a href="#" style={{ color: 'white' }}>Service</a>
                </li>
                <li>
                  <button onClick={handleLogout} className='rounded-5 px-3 cursor-pointer mb-1'>Logout</button>
                  {/* onClick={handleExportExcel}  */}
                  <button onClick={() => setShowModal(true)} className='rounded-5 px-3 cursor-pointer mb-1'>Download Excel</button>

                </li>
              </ul>
            )}
          </div>

          {/* Content */}
          <div style={contentStyle}>
            {activePage === 'home' && <HomeAdmin />}
            {activePage === 'contact' && <ContactAdmin />}
            {activePage === 'service' && <ServiceAdmin />}
            {activePage === 'all' && <AllAdminMsg />}

          </div>



          <div>
            {/* Button to trigger the modal */}
            {/* <button className="btn btn-primary" onClick={() => setShowModal(true)}>
              Download Data
            </button> */}

            {/* Modal */}
            {showModal && (
              <div
                className="modal show d-block"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1050,
                }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Download Data</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      {/* Dropdown for selecting date range */}
                      <div className="mb-3">
                        <label htmlFor="dateRange" className="form-label">
                          Select Date Range
                        </label>
                        <select
                          id="dateRange"
                          className="form-select"
                          value={selectedDateRange}
                          onChange={(e) => setSelectedDateRange(e.target.value)}
                        >
                          <option value="">-- Select Date Range --</option>
                          <option value="7d">Last 7 Days</option>
                          <option value="15d">Last 15 Days</option>
                          <option value="1m">Last 1 Month</option>
                          <option value="3m">Last 3 Months</option>
                          <option value="6m">Last 6 Months</option>
                          <option value="1y">Last 1 Year</option>
                          <option value="all">Complete Data</option>
                        </select>
                      </div>

                      {/* Dropdown for selecting section */}
                      <div className="mb-3">
                        <label htmlFor="section" className="form-label">
                          Select Section
                        </label>
                        <select
                          id="section"
                          className="form-select"
                          value={selectedSection}
                          onChange={(e) => setSelectedSection(e.target.value)}
                        >
                          <option value="">-- Select Section --</option>
                          <option value="home">Home</option>
                          <option value="contact-us">Contact Us</option>
                          <option value="services">Services</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="btn btn-secondary"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button className="btn btn-primary" onClick={handleExportExcel}>
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Admin;
